import React from "react";
import { Table, TableBody, TableRow, TableCell, Stack, Typography, useTheme } from "@mui/material";
import { prettyKeys } from "../../utils/helpers/prettificationHelpers";

const CompareTable = ({ keys, items }) => {
    const theme = useTheme()
    const tableBorder = { borderBottom: `1px solid ${theme.palette.grey[800]}` }

    return (
        <Table>
            <TableBody>
                {keys.map(key => {
                    const valueObj = items.find(item => item[key])
                    const values = valueObj[key];
                    
                    return (
                        <TableRow
                            sx={{
                                fontWeight: 400,
                            }}
                        >
                            {values.map((item, index) => {
                                const itemName = item ? item : "-"
                                return (
                                    <TableCell sx={{ fontWeight: "inherit", verticalAlign: "bottom", width: "50%", ...tableBorder }}>
                                        {
                                            index === 0 ? (
                                                <Stack spacing={0.5}>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: theme.palette.grey[600]
                                                        }}
                                                    >
                                                        {prettyKeys(key)}
                                                    </Typography>
                                                    <Typography>
                                                        {itemName}
                                                    </Typography>
                                                </Stack>
                                            ) :
                                                <Typography>{itemName}</Typography>
                                        }
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

export default CompareTable