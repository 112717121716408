import React from "react";
import { styled } from "@mui/material/styles";
import { Select } from "@mui/material";
import { theme } from "../../themes/themes";


const StyledSelect = styled(Select)(({ theme, ...props }) => ({
    backgroundColor: theme.palette.surface.raised,
    fontSize: "14px",
    borderRadius: "8px",
    "&:hover": {
        backgroundColor: theme.palette.surface.hover,
    },
    "&:focussed": {
        backgroundColor: theme.palette.surface.hover,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 0
    }
}))

export default function CustomSelect({ children, sx, onChange, ...otherProps }) {
    return (
        <StyledSelect
            sx={sx}
            onChange={onChange}
            MenuProps={{
                PaperProps: {
                    sx: {
                        backgroundColor: theme.palette.surface.main,
                        backgroundImage: "none",
                        padding: 0.5,
                        borderRadius: "8px",
                        "& ul": {
                            padding: 0,
                        },
                        "& li.Mui-selected": {
                            backgroundColor: `${theme.palette.grey["a900"]} !important`
                        }
                    },
                },
            }}
            {...otherProps}
        >
            {children}
        </StyledSelect>
    )
}