import { CssBaseline, ThemeProvider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageContainer from "../components/layout/PageContainer";
import { theme } from "../themes/themes";
import Nav from "../components/navigation/Nav";
import { useSingleProduct } from "../utils/useSingleProduct";
import { useAllProducts } from "../utils/useAllProducts"
import Select from "../components/input/Select"
import MenuItem from "../components/input/MenuItem"
import CompareTable from "../components/layout/CompareTable";
import Seo from "../components/Seo"
import slugify from "slugify";
import Footer from "../components/navigation/Footer";


const Compare = ({
    location,
    ...otherProps
}) => {
    const { products } = useAllProducts()
    const defaultItems = {
        compare1: useSingleProduct("Apple iPhone 14 Pro Max 5G"),
        compare2: useSingleProduct("Apple iPhone 14 Pro 5G")
    }
    const [state, setState] = useState([]);

    const getDefaults = () => {
        if (!location?.state?.productModel) {
            return defaultItems
        }

        const isProductModel = products.find(item => {
            const productSlug = slugify(item.Product.Model, {
                lower: true,
                trim: true,
            })

            return location.state.productModel.includes(productSlug)
        })


        return {
            compare1: isProductModel,
            compare2: null,
        }
    }

    useEffect(() => {
        setState(getDefaults())
    }, []);

    const handleChange = (event, itemKey) => {
        const getClickedProduct = products.find(item => item.Product.Model === event.target.value)

        const newState = {
            ...state,
            [itemKey]: getClickedProduct
        }

        setState(newState)
    }

    const flatObj = (obj) => {
        if (obj && typeof obj === "object") {
            return Object.entries(obj).reduce(
                (flattened, [key, value]) =>
                    typeof value == "object"
                        ? { ...flattened, ...flatObj(value) }
                        : { ...flattened, [key]: value },
                {}
            );
        }
        return;
    }

    const flattenedState = Object.values(state).map(item => {
        if (!item) {
            return;
        }
        delete item.Description;
        return flatObj(item)
    });
    const uniqueKeys = Object.keys(Object.assign({}, ...flattenedState))

    const getTransformedState = () => {
        return uniqueKeys.map(key => ({ [key]: flattenedState.map(item => item && item[key]) }))
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Seo
                location={location}
                passedTitle="Compare Device Screen Sizes"
                passedDescription="A tool to help guide you on the differences between device screen specifications"
            />
            <Nav location={location} />
            <PageContainer>
                <Stack
                    alignItems="center"
                    sx={{
                        width: "100%",
                        paddingBottom: "60px"
                    }}
                >
                    <Stack
                        sx={{
                            width: "100%",
                            maxWidth: "1200px"
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent={"space-evenly"}
                            spacing={2}
                            sx={{
                                width: "100%",
                                position: "sticky",
                                top: "61px",
                                backgroundColor: theme.palette.surface.main,
                                paddingTop: `${theme.spacing(2)}`,
                            }}
                        >
                            {Object.keys(state).map((item, index) => {
                                return (
                                    <Select
                                        onChange={(value) => handleChange(value, item)}
                                        value={state[item] ? state[item].Product.Model : null}
                                        sx={{
                                            width: "50%",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {products.map(product => {
                                            return (
                                                <MenuItem
                                                    value={product.Product.Model}
                                                >
                                                    {product.Product.Model}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                )
                            })}
                        </Stack>
                        <CompareTable keys={uniqueKeys} items={getTransformedState()} />
                    </Stack>
                </Stack>
                <Footer />
            </PageContainer>
        </ThemeProvider >
    )
}

export default Compare;