import { useStaticQuery, graphql } from "gatsby"
import { prettifiedProducts } from "./helpers/prettificationHelpers"

export const useSingleProduct = (productName) => {
    const data = useStaticQuery(
        graphql`
            query allProductsInGroups {
                allDevicesJson(sort: {fields: Product___Brand, order: DESC}) {
                    nodes {
                        Product {
                            Brand
                            Model
                        }
                        Display {
                            Color_Depth
                            Diagonal
                            Height
                            Illumination
                            Notch_es_
                            Pixel_Size
                            Pixel_Density
                            Refresh_Rate
                            Resolution__H_x_W_
                            Subpixels
                            Touch_screen_Type
                            Type
                            Width
                            Touch_Point_s_
                        }
                        Time {
                            Announced
                            Released
                        }
                        Category
                    }
                }
            }
        `
    )

    const products = prettifiedProducts(data.allDevicesJson.nodes)

    return products.find(({ Product }) => Product.Model === productName)
}